import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Text, {
  TEXT_ALIGN,
  TEXT_COLOR,
  TEXT_FONT_WEIGHT,
  TEXT_HEADING_LEVEL,
  TEXT_KIND,
  TEXT_SEGMENT,
  TEXT_SIZE,
} from "@/bit/components/components.text";
import Glyph from "@/bit/components/components.icon-glyph";
import CustomLink from "@/bit/components/components.custom-link";

export const COMPONENT_NAME = "Faqs";
export { TEXT_COLOR, TEXT_SEGMENT };

/**
 * Componente que permite crear una lista desplegable de FAQs.
 */
const Faqs = ({
  openAllQuestions,
  listadoFAQS,
  iconColor,
  titleColorClosed,
  titleColorOpen,
  borderBottomOnly,
  segment,
  className,
}) => {
  const [openIndices, setOpenIndices] = useState(
    openAllQuestions ? listadoFAQS.map((_, index) => index) : []
  );

  const toggleQuestion = (index) => {
    setOpenIndices((prevOpenIndices) =>
      prevOpenIndices.includes(index)
        ? prevOpenIndices.filter((i) => i !== index)
        : [...prevOpenIndices, index]
    );
  };

  // useEffect para manejar el scroll después de la animación
  useEffect(() => {
    openIndices.forEach((index) => {
      const faqDescription = document.querySelectorAll(".faq-description")[index];
      
      if (faqDescription) {
        faqDescription.classList.remove("show-scroll"); 

        setTimeout(() => {
          faqDescription.classList.add("show-scroll");
        }, 1000); // Tiempo igual al de la transición (1s)
      }
    });
  }, [openIndices]);

  const taggingInfo = {
    componentName: COMPONENT_NAME,
    componentContent: "",
    componentProduct: "",
    componentVariation: "none",
    eventType: "click",
  };

  return (
    <section className={`faqs ${className}`}>
      {listadoFAQS.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${
            borderBottomOnly
              ? "faq-item--with-bottom-border"
              : "faq-item--with-borders"
          }`}
        >
          <CustomLink
            title={faq.title}
            ariaLabel={faq.title}
            taggingInfo={{
              ...taggingInfo,
              componentProduct: `faqItem-${index}`,
              componentContent: openIndices.includes(index)
                ? "faq-plegar"
                : "faq-desplegar",
              componentVariation: "none",
              eventType: "click",
            }}
            className={`faq-title ${openIndices.includes(index) ? "open" : ""}`}
            onClick={() => toggleQuestion(index)}
          >
            <Text
              text={faq.title}
              kind={TEXT_KIND.HEADING}
              level={TEXT_HEADING_LEVEL.H3}
              align={TEXT_ALIGN.LEFT}
              color={
                openIndices.includes(index) ? titleColorOpen : titleColorClosed
              }
              size="subtitle1"
              fontType={TEXT_FONT_WEIGHT.REGULAR}
              segment={segment}
              className="text-content"
            />
            <span>
              <Glyph
                id="cheuron-up-m"
                color={iconColor}
                width={16}
                layoutSize={{
                  height: 16,
                  width: 16,
                }}
              />
            </span>
          </CustomLink>
          <div
            className={`faq-description ${
              openIndices.includes(index) ? "open" : ""
            }`}
          >
            <Text
              color={TEXT_COLOR.GREY6}
              size="body1"
              segment={segment}
              className="text-content"
              text={faq.description}
            />
          </div>
        </div>
      ))}
      <style jsx>{`.faqs {
  .faq-item {
    &--with-borders {
      border: 1px solid #eeeeee;
    }

    &--with-bottom-border {
      border-bottom: 1px solid #eeeeee;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  :global(.faq-title) {
    padding: 1rem 5px;
    background-color: transparent;
    width: 100%;
    outline-color: transparent;
    outline-width: 0px;
    position: relative;
    display: flex;
    justify-content: space-between;

    :global(img) {
      transform: rotate(180deg);
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }
  }

  :global(.faq-title.open) :global(img) {
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .faq-description {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-out;
    padding-right: 8px;
    transition: max-height 0.5s ease-out;

    :global(.text) {
      padding: 1rem;
      padding-top: 0px;
    }
  }

  .faq-description.open {
    transition: max-height 1s ease-out;
    max-height: 600px;
    overflow-y: hidden;
  }

  .faq-description.open.show-scroll {
    overflow-y: auto; /* Muestra el scroll solo cuando la animación ha terminado */
  }
  
  .faq-description::-webkit-scrollbar {
    width: 8px;
  }
  
  .faq-description::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .faq-description::-webkit-scrollbar-thumb {
    background-color: #B0B0B0;
    border-radius: 10px;
    border: 2px solid transparent;
  }
  
  .faq-description::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
  }

  :global(a) {
    color: #0079bb;

    &:hover {
      opacity: 0.8;
    }
  }
}
`}</style>
    </section>
  );
};

Faqs.propTypes = {
  /**
   * {
   *  "info": "Booleano que nos permite abrir todas las preguntas por defecto",
   *  "kind": "both",
   *  "beautifulName": "Expandido por defecto"
   * }
   */
  openAllQuestions: PropTypes.bool,
  /**
   * Listado de preguntas frecuentes/faqs
   */
  /**
   * {
   *  "info": "Listado de preguntas frecuentes/faqs",
   *  "kind": "both",
   *  "beautifulName": "Lista de elementos"
   * }
   */
  listadoFAQS: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  /**
   * {
   *  "info": "Color del icono",
   *  "kind": "both",
   *  "beautifulName": "Color del icono"
   * }
   */
  iconColor: PropTypes.string,
  /**
   * {
   *  "info": "Color de los títulos de las faqs abiertas",
   *  "kind": "both",
   *  "beautifulName": "Color del texto abierto"
   * }
   */
  titleColorOpen: PropTypes.string,
  /**
   * {
   *  "info": "Color de los títulos de las faqs cerradas",
   *  "kind": "both",
   *  "beautifulName": "Color del texto cerrado"
   * }
   */
  titleColorClosed: PropTypes.string,
  /**
   * {
   *  "info": "Faqs con border o no",
   *  "kind": "both",
   *  "beautifulName": "Borde de faqs"
   * }
   */
  borderBottomOnly: PropTypes.bool,
  /**
   * {
   *  "info": "Necesita para especificar el segmento",
   *  "kind": "both",
   *  "beautifulName": "Segmento"
   * }
   */
  segment: PropTypes.string,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "both",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
};

Faqs.defaultProps = {
  openAllQuestions: false,
  iconColor: TEXT_COLOR.BLUE,
  titleColorClosed: TEXT_COLOR.GREY5,
  titleColorOpen: TEXT_COLOR.GREY6,
  borderBottomOnly: true,
  segment: TEXT_SEGMENT.GP,
  className: "",
};

export default Faqs;
